<template>
  <v-container class="pa-0" v-if="item">
    <v-card
      :img="
        item.thumbnail && item.thumbnail.url ? item.thumbnail.url : highResImage
      "
      height="245"
      width="100%"
      style="border-radius: 15px !important"
      @click.stop="
        carousel
          ? $emit('carousel-clicked')
          : $router.safePush({
              name: 'detail',
              params: {
                id: item.id,
              },
            })
      "
      class="mt-3"
    >
      <div
        v-if="item.new && !item.insider"
        style="position: absolute; top: 0px; left: 16px"
      >
        <v-img :src="require('@/assets/flag.png')" />
        <span class="flag-content-new Text-White-Text-4---12-Left-Align">{{
          $t("dashboard.new")
        }}</span>
      </div>
      <div v-if="item.insider" style="position: absolute; top: 0px; left: 16px">
        <v-img :src="require('@/assets/flag.png')" />
        <span class="Text-White-Text-4---12-Left-Align flag-content-insider">{{
          $t("dashboard.insider")
        }}</span>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- Only show if rating available -->
        <template v-if="item.userRating && item.userRating > 0">
          <v-icon color="yellow darken-2"> mdi-star </v-icon>

          <span class="caption white--text">{{
            parseFloat(item.userRating).toFixed(1)
          }}</span>
        </template>

        <v-btn
          v-if="
            $store.state.profile.data &&
            $store.state.session.user &&
            !$store.state.isMiKY
          "
          icon
          large
          :class="$store.state.settings.dark ? '' : 'white--text'"
          @click.stop="toggleFavorite(item.id)"
          ><v-icon
            :color="
              find($store.state.profile.data.favorites, (fav) => {
                return fav === item.id;
              })
                ? 'red'
                : ''
            "
            >{{
              find($store.state.profile.data.favorites, (fav) => {
                return fav === item.id;
              })
                ? "mdi-heart"
                : "mdi-heart-outline"
            }}</v-icon
          >
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      elevation="6"
      min-height="133"
      width="100%"
      style="
        border-radius: 15px !important;
        margin-top: -100px
        position: relative !important;
      "
      @click.stop="
        carousel
          ? $emit('carousel-clicked')
          : $router.safePush({
              name: 'detail',
              params: {
                id: item.id,
              },
            })
      "
    >
      <v-icon
        v-if="item.weatherSuitability"
        icon
        color="yellow darken-2"
        style="width: 100%; position: absolute"
        class="mt-2"
        >mdi-white-balance-sunny</v-icon
      >
      <v-card-actions v-if="item.category">
        <template v-if="item.category.pictogram">
          <div class="mr-2 gradient pa-2 rounded-circle">
            <v-img
              class="filter"
              width="20px"
              height="auto"
              :src="item.category.pictogram"
            />
          </div>
        </template>
        <span
          :class="!item.category.pictogram ? 'ml-2' : ''"
          class="mt-1 Text-Dark-Text-3---14-Left-Align"
          :style="!$store.state.settings.dark ? 'color: #606060;' : ''"
        >
          {{ item.category.title }}
        </span>

        <v-spacer></v-spacer>
        <div
          v-if="item.priceSegment"
          class="Text-Dark-Text-3---14-Left-Align"
          :color="$store.state.settings.dark ? 'white--text' : 'black--text'"
        >
          {{ item.priceSegment }}
        </div>
      </v-card-actions>
      <v-card-title
        style="word-break: break-word"
        :class="!item.category ? 'pt-4' : ''"
        class="py-0 Heading-Dark-H4-18-Left-Align"
        v-if="item.header"
      >
        {{ item.header }}
      </v-card-title>
      <v-card-text
        class="Text-Dark-Text-3---14-Left-Align pb-2"
        :class="
          $store.state.settings.dark
            ? 'white--text'
            : 'grey--text text--darken-3'
        "
        style="word-break: break-word"
      >
        <div
          class="subHeader"
          :class="
            $store.state.settings.dark
              ? 'white--text'
              : 'grey--text text--darken-3'
          "
        >
          {{ item.subHeader }}
        </div>
      </v-card-text>
      <v-card-actions class="pt-0 px-4">
        <div
          v-if="openingTimes"
          v-html="openingTimes"
          class="v-html Text-Dark-Text-4---12-Left-Align"
          :class="$store.state.settings.dark ? 'white--text' : 'black--text'"
          style="word-break: break-word; width: 59%"
        ></div>
        <v-spacer v-else></v-spacer>
        <div style="text-align: right; width: 120px">
          <template v-if="distance">
            <span class="Text-Dark-Text-4---12-Right-Align"
              >{{ distance }} km</span
            >
            <v-img
              class="d-inline-block"
              :class="$store.state.settings.dark ? 'filter' : ''"
              height="auto"
              width="20"
              :src="require('@/assets/distance.svg')"
            />
          </template>

          <span v-if="item.priceSegment" class="mb-0" style="font-size: 12px">
            {{ item.priceSegment }}
          </span>
        </div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import find from "lodash/find";
import api from "@/api";
export default {
  data() {
    return {
      priceIndicator: "$$$$",
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carousel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    highResImage() {
      let imageUrl = this.item.affiliateImage;

      if (!imageUrl) {
        return require("../../assets/image_placeholder_small.png");
      }

      if (this.item.source === "reserve-out") {
        let imageSource = imageUrl.slice(0, 49);
        let imageName = imageUrl.split("/")[imageUrl.split("/").length - 1];
        let highResUrl = imageUrl;
        if (
          imageSource === "https://ro-images-reserveout.netdna-ssl.com/crop/"
        ) {
          highResUrl = imageSource + "1404x936/" + imageName;
        }
        return highResUrl;
      } else if (this.item.source === "getyourguide") {
        let imageName = imageUrl.substr(imageUrl.length - 7);
        let highResUrl = imageUrl;
        if (imageName === "131.jpg") {
          highResUrl = imageUrl.slice(0, imageUrl.length - 7) + "107.jpg";
        }
        return highResUrl;
      } else if (this.item.source === "musement") {
        let imageWidth = imageUrl.substr(imageUrl.length - 5);
        let highResUrl = imageUrl;
        if (imageWidth === "w=540") {
          highResUrl = imageUrl.slice(0, imageUrl.length - 5) + "w=1404";
        }
        return highResUrl;
      } else {
        return imageUrl;
      }
    },
    distance() {
      try {
        return api.getDistanceToLocation(this.item);
      } catch (error) {
        console.error("Failed to get distance");
        return "0";
      }
    },
    openingTimes() {
      return api.getOpeningHours(this.item);
    },
  },
  methods: {
    ...mapActions("favorites", ["toggleFavorite"]),
    find,
  },
};
</script>

<style lang="scss" scoped>
.flag-content-new {
  position: absolute;
  bottom: 0;
  left: 35px;
  top: 3px;
  color: #fff;
  text-transform: uppercase;
}
.flag-content-insider {
  position: absolute;
  color: #fff;
  top: 0;
  text-transform: uppercase;
  left: 15px;
  top: 3px;
}
</style>
