<template>
  <div
    @touchstart.capture="touchStart"
    @touchmove.capture="touchMove"
    @touchend.capture="touchEnd"
  >
    <v-card
      flat
      style="border-radius: 12px !important"
      :style="!$store.state.settings.dark ? 'background-color: #f5f5f5;' : ''"
      :ripple="false"
    >
      <v-row no-gutters class="mb-2">
        <v-col class="shrink">
          <div>
            <v-img
              eager
              width="120"
              height="124"
              style="border-radius: 12px 0px 0px 12px !important"
              :src="
                item.promotion
                  ? item.promotion.imageUrl
                  : item.restaurant.imageUrl
              "
            />
            <div
              id="img-top"
              class="corner"
              :style="
                !$store.state.settings.dark
                  ? 'background: #fff'
                  : 'background: #121212'
              "
            ></div>
            <div
              id="txt-top"
              class="corner"
              :style="
                !$store.state.settings.dark
                  ? 'background: #fff'
                  : 'background: #121212'
              "
            ></div>
            <div
              id="img-bottom"
              class="corner"
              :style="
                !$store.state.settings.dark
                  ? 'background: #fff'
                  : 'background: #121212'
              "
            ></div>
            <div
              id="txt-bottom"
              class="corner"
              :style="
                !$store.state.settings.dark
                  ? 'background: #fff'
                  : 'background: #121212'
              "
            ></div>
          </div>
          <template v-if="$route.name === 'bookings'">
            <template v-if="daysLeft >= 0">
              <v-img
                :src="require('@/assets/flag.png')"
                width="94"
                height="auto"
                style="position: absolute; top: 0; left: 10px"
              ></v-img>
              <span
                class="flag-content Text-White-Text-4---12-Left-Align"
                v-if="daysLeft === 0"
              >
                {{ $t("reservation.today") }}
              </span>
              <span
                v-else
                class="flag-content Text-White-Text-4---12-Left-Align"
                >{{ $t("reservation.inDays", { days: daysLeft }) }}</span
              >
            </template>
          </template>
          <template v-else-if="item.cancelled">
            <v-img
              :src="require('@/assets/flag_red.png')"
              width="94"
              height="auto"
              style="position: absolute; top: 0; left: 10px"
            ></v-img>
            <span class="flag-content Text-White-Text-4---12-Left-Align">{{
              $t("reservation.canceled")
            }}</span>
          </template>
        </v-col>
        <v-col class="grow">
          <v-container class="py-0 px-2">
            <div v-if="item.promotion">
              <div
                v-line-clamp="1"
                class="Heading-Dark-H4-18-Left-Align pt-3 mb-1"
              >
                {{ item.promotion.name }}
              </div>
              <div
                v-line-clamp="1"
                class="mt-n2 Text-Dark-Text-2---16-Left-Align"
              >
                {{ item.restaurant.name }}
              </div>
            </div>
            <div
              v-else
              v-line-clamp="1"
              class="Heading-Dark-H4-18-Left-Align pt-5 mb-1"
            >
              {{ item.restaurant.name }}
            </div>
            <span class="Heading-Dark-H6-14-Left-Align"> {{ showDate }}</span>
            <v-divider class="ml-1" vertical></v-divider>
            <span class="Heading-Dark-H6-14-Left-Align">
              {{ time }}
            </span>
            <v-divider vertical></v-divider>
            <span class="Heading-Dark-H6-14-Left-Align">
              {{
                item.covers +
                " " +
                (item.covers == 1
                  ? $t("reservation.guest")
                  : $t("reservation.guests"))
              }}</span
            >
            <v-card-actions
              v-if="$route.name === 'bookings'"
              class="pa-0"
              style="position: absolute; bottom: 12px; right: 12px"
            >
              <v-btn icon width="24" height="24" @click="location = true"
                ><img
                  :class="$store.state.settings.dark ? 'filter' : ''"
                  :src="require('@/assets/reservation/location.svg')"
              /></v-btn>
              <v-btn icon width="24" height="24" @click="edit = true"
                ><img
                  :class="$store.state.settings.dark ? 'filter' : ''"
                  :src="require('@/assets/reservation/modify.svg')"
              /></v-btn>
            </v-card-actions>
            <v-card-actions
              v-else
              class="pa-0"
              :class="item.promotion ? 'mt-2' : 'mt-4'"
              ><v-spacer></v-spacer
              ><v-btn
                color="primary"
                class="gradient"
                rounded
                height="24"
                @click="
                  item.promotionId === null
                    ? $router.safePush({
                        name: 'reservation',
                        params: {
                          id: item.recommendationId,
                          header: item.restaurant.name,
                        },
                      })
                    : $router.safePush({
                        name: 'ticketdetail',
                        params: {
                          restaurantId: item.recommendationId,
                          ticketId: item.promotionId,
                          type: item.source,
                        },
                      })
                "
                >{{ $t("reservation.bookAgain") }}</v-btn
              ><v-spacer></v-spacer
            ></v-card-actions>
            <open-in-maps
              :lat="lat"
              :lng="lng"
              :value="location"
              @cancel="location = false"
            ></open-in-maps>
            <v-bottom-sheet
              v-model="edit"
              width="90%"
              overlay-color="backgroundoverlay"
              overlay-opacity="0.7"
            >
              <v-sheet style="background: transparent">
                <v-list style="border-radius: 10px">
                  <v-list-item>
                    <v-list-item-content @click="modifyBooking()">
                      <v-list-item-title class="Text-Dark-Text-1---18-Center">
                        {{ $t("reservation.modifyBooking") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content @click="cancelDialog = true">
                      <v-list-item-title class="Text-Dark-Text-1---18-Center">
                        {{ $t("reservation.cancelBooking") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list style="border-radius: 10px" class="mt-2 mb-5">
                  <v-list-item>
                    <v-list-item-content @click="edit = false"
                      ><v-list-item-title class="Text-Dark-Text-1---18-Center">
                        {{ $t("reservation.ignore") }}</v-list-item-title
                      ></v-list-item-content
                    >
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-bottom-sheet>
            <v-dialog v-model="cancelDialog" @blur="cancelDialog = false">
              <v-card flat style="border-radius: 15px !important" class="pb-8">
                <v-toolbar dense flat>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="cancelDialog = false"
                    :style="!$store.state.settings.dark ? 'color: #09212b' : ''"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-title
                  class="justify-center Text-Dark-Text-1---18-Center pt-12"
                  style="word-break: break-word"
                >
                  {{ $t("reservation.confirmCancel") }}</v-card-title
                >
                <v-card-actions class="justify-center pb-12 pt-7">
                  <v-btn
                    height="48"
                    min-width="78"
                    class="gradient Heading-White-H4-18-Left-Align mr-6"
                    dark
                    depressed
                    @click="cancelDialog = false"
                    >{{ $t("reservation.no") }}</v-btn
                  >
                  <v-btn
                    height="48"
                    min-width="78"
                    class="gradient Heading-White-H4-18-Left-Align"
                    dark
                    depressed
                    @click="cancelReservation"
                    >{{ $t("reservation.yes") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="$route.name !== 'bookings'"
      class="delete"
      style="max-width: none"
      @click="deleteBooking"
    >
      <img src="@/assets/img/delete.svg" />
      <span
        class="Heading-White-H4-18-Left-Align"
        style="margin-top: 8px; margin-left: 5px"
        >{{ $t("activityhistory.delete") }}</span
      >
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import OpenInMaps from "@/components/app/maps/OpenInMaps";
export default {
  data: () => ({
    location: false,
    edit: false,
    cancelDialog: false,
    startX: 0,
    startY: 0,
    noDelete: false,
    currentOffsetX: 0,
  }),
  components: {
    OpenInMaps,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    lat() {
      return this.item.restaurant.latitude;
    },
    lng() {
      return this.item.restaurant.longitude;
    },
    daysLeft() {
      let days = "";
      const a = moment(this.date, "YYYY-MM-DD");
      const b = moment(moment(new Date()).format("YYYY-MM-DD"));
      days = a.diff(b, "days");
      return days;
    },
    showDate() {
      return moment(this.date, "YYYY-MM-DD").format("ddd DD, MMM");
    },
    date() {
      return (
        this.item.arrival[0] +
        "-" +
        (this.item.arrival[1] < 10
          ? "0" + this.item.arrival[1]
          : this.item.arrival[1]) +
        "-" +
        (this.item.arrival[2] < 10
          ? "0" + this.item.arrival[2]
          : this.item.arrival[2])
      );
    },
    time() {
      return (
        this.item.arrival[3].toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":" +
        this.item.arrival[4].toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
      );
    },
  },
  methods: {
    cancelReservation() {
      this.edit = false;
      this.cancelDialog = false;
      this.$store.dispatch("reserveout/cancelReservation", {
        visitId: this.item.visitId,
      });
    },
    modifyBooking() {
      this.edit = false;
      this.$store.commit("reserveout/SET_PARAMS", {
        placeId: this.item.restaurant.id,
        covers: this.item.covers,
        date: this.date,
        time: this.time,
        header: this.item.restaurant.name,
        areaId: this.item.area.id,
        countryId: this.item.restaurant.countryId,
        visitId: this.item.visitId,
        promotionId: this.item.promotionId,
      });
      this.$router.safePush({
        name: "modifyBooking",
        params: {
          placeId: this.item.restaurant.id,
        },
        // BUGFIX ticket #260
        // If the user cancels booking in "ChooseArea", then "ModifyBooking"
        // doesn't receive visitId. Now with visitId in query, it does so.
        query: {
          visitId: this.item.visitId,
        },
      });
    },
    touchStart: function (e) {
      if (this.$router.currentRoute.name === "bookings") {
        return;
      }
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
      e.currentTarget.parentElement.style.transition = "transform 0s";
    },
    touchMove: function (e) {
      if (this.$router.currentRoute.name === "bookings") {
        return;
      }
      if (
        Math.abs(this.startY - e.changedTouches[0].clientY) >
        Math.abs(this.startX - e.changedTouches[0].clientX)
      ) {
        e.currentTarget.parentElement.style.transition = "transform 0.5s";
        e.currentTarget.parentElement.style.transform = "translateX(0)";
        this.noDelete = true;
      }
      if (this.noDelete) {
        return;
      }
      var lockinplaceLimit = Math.round(window.innerWidth * -0.385);
      var deleteLimit = Math.round(window.innerWidth * -0.5);
      var endX = e.changedTouches[0].clientX;
      let movement = 0 + endX - this.startX + this.currentOffsetX;
      if (movement < lockinplaceLimit) {
        movement = 0 + (endX - this.startX) * 1.2 + this.currentOffsetX;
      }
      if (movement > 0) {
        return;
      }
      if (movement < deleteLimit) {
        e.currentTarget.parentElement.style.transition = "transform 0.5s";
        e.currentTarget.parentElement.style.transform =
          "translateX(-" + (-24 + window.innerWidth) + "px)";
      } else {
        e.currentTarget.parentElement.style.transition = "transform 0s";
        e.currentTarget.parentElement.style.transform =
          "translateX(" + movement + "px)";
      }
    },
    touchEnd: function (e) {
      if (this.$router.currentRoute.name === "bookings") {
        return;
      }
      if (this.noDelete) {
        this.noDelete = false;
        return;
      }
      var lockinplaceLimit = Math.round(window.innerWidth * -0.385);
      var deleteLimit = Math.round(window.innerWidth * -0.5);
      if (movement < lockinplaceLimit) {
        movement = 0 + (this.endX - this.startX) * 1.2 + this.currentOffsetX;
      }
      var endX = e.changedTouches[0].clientX;
      let movement = 0 + endX - this.startX + this.currentOffsetX;
      if (movement >= lockinplaceLimit * 0.5) {
        e.currentTarget.parentElement.style.transition = "transform 0.5s";
        e.currentTarget.parentElement.style.transform = "translateX(0)";
        this.currentOffsetX = 0;
      } else if (movement < lockinplaceLimit * 0.5 && movement > deleteLimit) {
        e.currentTarget.parentElement.style.transition = "transform 1s";
        e.currentTarget.parentElement.style.transform =
          "translateX(" + lockinplaceLimit + "px)";
        this.currentOffsetX = lockinplaceLimit;
      } else if (movement < deleteLimit) {
        this.deleteBooking();
        this.currentOffsetX = movement;
      } else {
        this.currentOffsetX = movement;
      }
    },
    deleteBooking() {
      this.$store.dispatch(
        "profile/removeActivityHistoryItem",
        {
          visitId: this.item.visitId,
        },
        { root: true }
      );
      this.$emit("deletebooking", this);
    },
  },
};
</script>
<style scoped>
.v-divider--vertical {
  border: 1px solid #c1c7ca;
  height: 20px;
  vertical-align: middle;
}

.corner {
  height: 12px;
  width: 12px;
  position: absolute;
}
#img-bottom {
  right: -2px;
  bottom: 0px;
  left: 110px;
  border-radius: 12px 0px 0px 0px;
}
#img-top {
  right: -2px;
  top: 0px;
  left: 110px;
  border-radius: 0px 0px 0px 12px;
}
#txt-top {
  left: 120px;
  top: 0px;
  border-radius: 0px 0px 12px 0px;
}
#txt-bottom {
  left: 120px;
  bottom: 0px;
  border-radius: 0px 12px 0px 0px;
}
.flag-content {
  position: absolute;
  bottom: 0;
  width: 115px;
  top: 3px;
  color: #fff;
  text-align: center;
}
/deep/ .v-dialog.v-bottom-sheet {
  box-shadow: none !important;
}
div.delete {
  width: calc(100% + 16px);
  height: calc(100% - 16px);
  margin-top: 4px;
  background: #ca0e1f;
  border-radius: 12px !important;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 36px;
  position: absolute;
  top: 0;
  right: calc(-100% - 20px);
}
template.colapsed {
  visibility: collapse;
}
</style>
