var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('v-container',{staticClass:"pa-0"},[_c('v-card',{staticClass:"mt-3",staticStyle:{"border-radius":"15px !important"},attrs:{"img":_vm.item.thumbnail && _vm.item.thumbnail.url ? _vm.item.thumbnail.url : _vm.highResImage,"height":"245","width":"100%"},on:{"click":function($event){$event.stopPropagation();_vm.carousel
        ? _vm.$emit('carousel-clicked')
        : _vm.$router.safePush({
            name: 'detail',
            params: {
              id: _vm.item.id,
            },
          })}}},[(_vm.item.new && !_vm.item.insider)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"16px"}},[_c('v-img',{attrs:{"src":require('@/assets/flag.png')}}),_c('span',{staticClass:"flag-content-new Text-White-Text-4---12-Left-Align"},[_vm._v(_vm._s(_vm.$t("dashboard.new")))])],1):_vm._e(),(_vm.item.insider)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"16px"}},[_c('v-img',{attrs:{"src":require('@/assets/flag.png')}}),_c('span',{staticClass:"Text-White-Text-4---12-Left-Align flag-content-insider"},[_vm._v(_vm._s(_vm.$t("dashboard.insider")))])],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),(_vm.item.userRating && _vm.item.userRating > 0)?[_c('v-icon',{attrs:{"color":"yellow darken-2"}},[_vm._v(" mdi-star ")]),_c('span',{staticClass:"caption white--text"},[_vm._v(_vm._s(parseFloat(_vm.item.userRating).toFixed(1)))])]:_vm._e(),(
          _vm.$store.state.profile.data &&
          _vm.$store.state.session.user &&
          !_vm.$store.state.isMiKY
        )?_c('v-btn',{class:_vm.$store.state.settings.dark ? '' : 'white--text',attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite(_vm.item.id)}}},[_c('v-icon',{attrs:{"color":_vm.find(_vm.$store.state.profile.data.favorites, function (fav) {
              return fav === _vm.item.id;
            })
              ? 'red'
              : ''}},[_vm._v(_vm._s(_vm.find(_vm.$store.state.profile.data.favorites, function (fav) { return fav === _vm.item.id; }) ? "mdi-heart" : "mdi-heart-outline"))])],1):_vm._e()],2)],1),_c('v-card',{staticStyle:{"border-radius":"15px !important","margin-top":"-100px"},attrs:{"elevation":"6","min-height":"133","width":"100%"},on:{"click":function($event){$event.stopPropagation();_vm.carousel
        ? _vm.$emit('carousel-clicked')
        : _vm.$router.safePush({
            name: 'detail',
            params: {
              id: _vm.item.id,
            },
          })}}},[(_vm.item.weatherSuitability)?_c('v-icon',{staticClass:"mt-2",staticStyle:{"width":"100%","position":"absolute"},attrs:{"icon":"","color":"yellow darken-2"}},[_vm._v("mdi-white-balance-sunny")]):_vm._e(),(_vm.item.category)?_c('v-card-actions',[(_vm.item.category.pictogram)?[_c('div',{staticClass:"mr-2 gradient pa-2 rounded-circle"},[_c('v-img',{staticClass:"filter",attrs:{"width":"20px","height":"auto","src":_vm.item.category.pictogram}})],1)]:_vm._e(),_c('span',{staticClass:"mt-1 Text-Dark-Text-3---14-Left-Align",class:!_vm.item.category.pictogram ? 'ml-2' : '',style:(!_vm.$store.state.settings.dark ? 'color: #606060;' : '')},[_vm._v(" "+_vm._s(_vm.item.category.title)+" ")]),_c('v-spacer'),(_vm.item.priceSegment)?_c('div',{staticClass:"Text-Dark-Text-3---14-Left-Align",attrs:{"color":_vm.$store.state.settings.dark ? 'white--text' : 'black--text'}},[_vm._v(" "+_vm._s(_vm.item.priceSegment)+" ")]):_vm._e()],2):_vm._e(),(_vm.item.header)?_c('v-card-title',{staticClass:"py-0 Heading-Dark-H4-18-Left-Align",class:!_vm.item.category ? 'pt-4' : '',staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.item.header)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"Text-Dark-Text-3---14-Left-Align pb-2",class:_vm.$store.state.settings.dark
          ? 'white--text'
          : 'grey--text text--darken-3',staticStyle:{"word-break":"break-word"}},[_c('div',{staticClass:"subHeader",class:_vm.$store.state.settings.dark
            ? 'white--text'
            : 'grey--text text--darken-3'},[_vm._v(" "+_vm._s(_vm.item.subHeader)+" ")])]),_c('v-card-actions',{staticClass:"pt-0 px-4"},[(_vm.openingTimes)?_c('div',{staticClass:"v-html Text-Dark-Text-4---12-Left-Align",class:_vm.$store.state.settings.dark ? 'white--text' : 'black--text',staticStyle:{"word-break":"break-word","width":"59%"},domProps:{"innerHTML":_vm._s(_vm.openingTimes)}}):_c('v-spacer'),_c('div',{staticStyle:{"text-align":"right","width":"120px"}},[(_vm.distance)?[_c('span',{staticClass:"Text-Dark-Text-4---12-Right-Align"},[_vm._v(_vm._s(_vm.distance)+" km")]),_c('v-img',{staticClass:"d-inline-block",class:_vm.$store.state.settings.dark ? 'filter' : '',attrs:{"height":"auto","width":"20","src":require('@/assets/distance.svg')}})]:_vm._e(),(_vm.item.priceSegment)?_c('span',{staticClass:"mb-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.item.priceSegment)+" ")]):_vm._e()],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }