<template>
  <v-card
    flat
    style="border-radius: 12px !important"
    :style="!$store.state.settings.dark ? 'background-color: #f5f5f5;' : ''"
    :ripple="false"
    @click.stop="goToItem()"
    class="card-outer"
  >
    <v-row no-gutters v-if="!item.promotion">
      <v-col class="shrink">
        <v-img
          eager
          class="image-bg"
          width="120"
          height="124"
          style="border-radius: 12px !important"
          :position="item.source === 'static' ? '-5px center' : ''"
          :src="
            item.thumbnail && item.thumbnail.url
              ? item.thumbnail.url
              : highResImage
          "
        >
        </v-img>
        <div
          v-if="item.specialDeals && !item.new && !item.insider"
          class="card-promo white--text"
        >
          {{ $t("detail.specialDeals") }}
        </div>
        <div
          v-if="item.new && !item.insider"
          style="position: absolute; top: 0px; left: 16px"
        >
          <v-img :src="require('@/assets/flag.png')" />
          <span class="flag-content-new Text-White-Text-4---12-Left-Align">{{
            $t("dashboard.new")
          }}</span>
        </div>
        <div
          v-if="item.insider"
          style="position: absolute; top: 0px; left: 16px"
        >
          <v-img :src="require('@/assets/flag.png')" />
          <span
            class="Text-White-Text-4---12-Left-Align flag-content-insider"
            >{{ $t("dashboard.insider") }}</span
          >
        </div>
      </v-col>
      <v-col class="grow">
        <v-toolbar
          height="48px"
          flat
          class="pa-0 ma-0"
          style="
            border-radius: 12px 12px 0 0 !important;
            background: transparent;
          "
        >
          <category :category="item.category"></category>
          <v-spacer></v-spacer>
          <v-btn
            icon
            large
            @click.stop="toggleFavorite(item.id)"
            v-if="
              $store.state.profile.data &&
              $store.state.session.user &&
              !$store.state.isMiKY &&
              item.id
            "
            ><v-icon
              :color="
                find($store.state.profile.data.favorites, (fav) => {
                  return fav === item.id;
                })
                  ? 'red'
                  : '#475960'
              "
              >{{
                find($store.state.profile.data.favorites, (fav) => {
                  return fav === item.id;
                })
                  ? "mdi-heart"
                  : "mdi-heart-outline"
              }}</v-icon
            ></v-btn
          >
        </v-toolbar>
        <v-container class="py-0">
          <div v-line-clamp="1" class="Heading-Dark-H4-18-Left-Align">
            {{ item.header }}
          </div>
          <div
            class="Text-Dark-Text-3---14-Left-Align"
            v-line-clamp="1"
            v-if="item.subHeader"
          >
            {{ item.subHeader }}
          </div>
          <v-card-actions
            class="pa-0 card-actions"
            :class="!item.subHeader ? 'mt-5' : ''"
          >
            <div
              v-if="item.openingTimes || openingTimes"
              v-html="item.openingTimes || openingTimes"
              class="v-html Text-Dark-Text-4---12-Left-Align"
              style="word-break: break-word; width: 59%"
            ></div>
            <v-spacer></v-spacer>
            <div style="text-align: right; width: 120px">
              <template v-if="distance">
                <span class="Text-Dark-Text-4---12-Right-Align"
                  >{{ distance }} km</span
                >
                <v-img
                  class="d-inline-block"
                  :class="$store.state.settings.dark ? 'filter' : ''"
                  height="auto"
                  width="20"
                  :src="require('@/assets/distance.svg')"
                />
              </template>

              <span
                v-if="item.priceSegment"
                class="mb-0"
                style="font-size: 12px"
              >
                {{ item.priceSegment }}
              </span>
            </div>
          </v-card-actions>
        </v-container>
      </v-col>
    </v-row>
    <v-row no-gutters v-else>
      <v-col class="shrink">
        <v-img
          eager
          class="image-bg"
          width="120"
          height="124"
          style="border-radius: 12px !important"
          :src="item.promotion.imageUrl"
        >
        </v-img>
      </v-col>
      <v-col class="grow">
        <v-toolbar
          height="48px"
          flat
          class="pa-0 ma-0"
          style="
            border-radius: 12px 12px 0 0 !important;
            background: transparent;
          "
        >
          <category :category="item.category"></category>
          <v-spacer></v-spacer>
          <v-btn
            icon
            large
            @click.stop="
              toggleFavorite({
                pocoId: item.id,
                placeId: item.promotion.placeId,
                ticketid: item.promotion.id,
                type: item.source,
              })
            "
            v-if="
              $store.state.profile.data &&
              $store.state.session.user &&
              !$store.state.isMiKY
            "
            ><v-icon
              :color="
                find($store.state.profile.data.favorites, (fav) => {
                  return fav.ticketid === item.promotion.id;
                })
                  ? 'red'
                  : '#475960'
              "
              >{{
                find($store.state.profile.data.favorites, (fav) => {
                  return fav.ticketid === item.promotion.id;
                })
                  ? "mdi-heart"
                  : "mdi-heart-outline"
              }}</v-icon
            ></v-btn
          >
        </v-toolbar>
        <v-container class="py-0 mt-n1">
          <div v-line-clamp="1" class="Heading-Dark-H4-18-Left-Align">
            {{ item.promotion.name }}
          </div>
          <div v-line-clamp="1" class="mt-n1 Text-Dark-Text-2---16-Left-Align">
            {{ item.header }}
          </div>
          <v-card-actions class="mt-1 pa-0 card-actions">
            <div
              class="Text-Dark-Text-3---14-Left-Align"
              v-line-clamp="1"
              v-if="item.promotion.summary"
            >
              {{ item.promotion.summary }}
            </div>
            <v-spacer></v-spacer>
            <div style="text-align: right; width: 120px">
              <template v-if="distance">
                <span class="Text-Dark-Text-4---12-Right-Align"
                  >{{ distance }} km</span
                >
                <v-img
                  class="d-inline-block"
                  :class="$store.state.settings.dark ? 'filter' : ''"
                  height="auto"
                  width="20"
                  :src="require('@/assets/distance.svg')"
                />
              </template>

              <span
                v-if="item.priceSegment"
                class="mb-0"
                style="font-size: 12px"
              >
                {{ item.priceSegment }}
              </span>
            </div>
          </v-card-actions>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Category from "@/components/recommendations/Category";
import { mapActions } from "vuex";
import find from "lodash/find";
import api from "@/api";
export default {
  components: {
    Category,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    distance() {
      try {
        return api.getDistanceToLocation(this.item);
      } catch (error) {
        console.error("Failed to get distance");
        return "0";
      }
    },
    openingTimes() {
      return api.getOpeningHours(this.item);
    },
    highResImage() {
      let imageUrl = this.item.affiliateImage;

      if (!imageUrl) {
        return require("../../assets/image_placeholder_small.png");
      }

      if (this.item.source === "reserve-out") {
        let imageSource = imageUrl.slice(0, 49);
        let imageName = imageUrl.split("/")[imageUrl.split("/").length - 1];
        let highResUrl = imageUrl;
        if (
          imageSource === "https://ro-images-reserveout.netdna-ssl.com/crop/"
        ) {
          highResUrl = imageSource + "1404x936/" + imageName;
        }
        return highResUrl;
      } else if (this.item.source === "getyourguide") {
        let imageName = imageUrl.substr(imageUrl.length - 7);
        let highResUrl = imageUrl;
        if (imageName === "131.jpg") {
          highResUrl = imageUrl.slice(0, imageUrl.length - 7) + "107.jpg";
        }
        return highResUrl;
      } else if (this.item.source === "musement") {
        let imageWidth = imageUrl.substr(imageUrl.length - 5);
        let highResUrl = imageUrl;
        if (imageWidth === "w=540") {
          highResUrl = imageUrl.slice(0, imageUrl.length - 5) + "w=1404";
        }
        return highResUrl;
      } else {
        return imageUrl;
      }
    },
  },
  methods: {
    ...mapActions("favorites", ["toggleFavorite"]),
    find,
    goToItem() {
      if (this.item.source === "static") {
        let link = this.item.callToAction;
        if (
          this.$store.state.isMiKY &&
          (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
        ) {
          window.location.assign(link);
        } else {
          window.open(link, "_system");
        }
      } else if (this.item.source === "ticket") {
        this.$router.safePush({
          name: "ticketstandalone",
          params: {
            ticketId: this.item.id,
          },
        });
      } else {
        if (this.item.promotion) {
          this.$router.safePush({
            name: "ticketdetail",
            params: {
              restaurantId: this.item.id,
              ticketId: this.item.promotion.id,
              type: this.item.source,
            },
          });
        } else {
          this.$router.safePush({
            name: "detail",
            params: {
              id: this.item.id,
            },
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.flag-content-new {
  position: absolute;
  bottom: 0;
  left: 35px;
  top: 3px;
  color: #fff;
  text-transform: uppercase;
}
.flag-content-insider {
  position: absolute;
  color: #fff;
  top: 0;
  text-transform: uppercase;
  left: 15px;
  top: 3px;
}
.card-outer {
  position: relative;
  padding-bottom: 0px;
}
.card-promo {
  position: absolute;
  top: 7px;
  left: 16px;
  background-color: #ea9c06;
  font-family: Hind;
  font-size: 10px;
  text-transform: uppercase;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding: 1px 6px;
}
</style>
