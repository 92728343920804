<template>
  <v-container class="pt-0 px-0" style="overflow-x: hidden">
    <v-row v-if="showSegmentedControl && !noLocationItemsFound">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 1 : 2" class="mr-2 py-0">
        <v-btn
          rounded
          class="ma-1"
          @click="selectedSegment = -1 && $emit('switchedSegment')"
          :class="selectedSegment === -1 ? 'gradient white--text' : ''"
          >{{ $t("dashboard.all") }}</v-btn
        >
      </v-col>
      <v-col cols="9" class="pr-0 py-0">
        <v-layout style="overflow: hidden; overflow-x: auto">
          <v-flex
            v-for="(segment, index) in segments"
            :key="`segment-${index}`"
          >
            <v-btn
              rounded
              class="ma-1"
              @click="
                (selectedSegment === index
                  ? (selectedSegment = -1)
                  : (selectedSegment = index)) && $emit('switchedSegment')
              "
              :color="selectedSegment === index ? segment.color : ''"
              :class="selectedSegment === index ? 'white--text' : ''"
              >{{ $t(`categories.${segment.title}`) }}</v-btn
            ></v-flex
          >
          <v-flex
            v-for="index in segments.length < 7 ? 7 - segments.length : 0"
            :key="`segment-filler-${index}`"
            style="width: 73px"
          />
        </v-layout>
      </v-col>
    </v-row>
    <v-skeleton-loader
      type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar"
      :loading="loading"
      ><v-container class="px-1">
        <v-row dense>
          <v-col
            cols="12"
            v-for="(item, index) in sortedItems.slice(0, this.rendered)"
            :key="'card-' + index"
          >
            <just-for-you-card
              :item="item"
              v-if="$route.query.type === 'justForYou'"
            ></just-for-you-card>
            <highlight-card
              v-else-if="$route.query.type === 'highlights'"
              :item="item"
            ></highlight-card>
            <booking-card
              :item="item"
              @deletebooking="vanishItem($event)"
              v-else-if="
                $route.name === 'bookings' || $route.name === 'activityhistory'
              "
            ></booking-card>
            <ticket-card
              :item="item"
              v-else-if="$route.name === 'tickets'"
            ></ticket-card>
            <recommendation-card :item="item" v-else> </recommendation-card>
          </v-col>
        </v-row>
        <error-image
          v-if="!sortedItems.length && !loading"
          :height="noLocationItemsFound ? '200' : '325'"
          :text="
            errorImage === 'noResults.png'
              ? $t('search.noResults')
              : $t('search.noRecommendations')
          "
          :imageName="errorImage"
          :noLocationItemsFound="noLocationItemsFound"
        ></error-image>
      </v-container>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import RecommendationCard from "@/components/recommendations/RecommendationCard";
import JustForYouCard from "@/components/recommendations/JustForYouCard";
import HighlightCard from "@/components/recommendations/HighlightCard";
import ErrorImage from "@/components/app/common/ErrorImage";
import BookingCard from "@/components/recommendations/BookingCard";
import TicketCard from "@/components/recommendations/TicketCard";
import { mapGetters } from "vuex";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
export default {
  data() {
    return {
      lastAmount: 0,
    };
  },
  components: {
    RecommendationCard,
    JustForYouCard,
    HighlightCard,
    ErrorImage,
    BookingCard,
    TicketCard,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showSegmentedControl: {
      type: Boolean,
      default: true,
    },
    errorImage: {
      type: String,
      default: "",
    },
    noLocationItemsFound: {
      type: Boolean,
      default: false,
    },
    rendered: {
      type: Number,
      default: 100000,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "detail") this.selectedSegment = -1;
    next();
  },
  computed: {
    ...mapGetters("categories", ["filteredCategories"]),
    selectedSegment: {
      get() {
        return this.$store.state.session.selectedSegment;
      },
      set(val) {
        this.$store.commit("session/SET_SELECTED_SEGMENT", val);
      },
    },
    segments() {
      let segmentItems = [];
      if (this.showSegmentedControl) {
        this.filteredCategories.forEach((category) => {
          if (
            findIndex(this.items, (item) => {
              if (item.category) {
                return item.category?.id === category.id;
              } else {
                return false;
              }
            }) >= 0
          ) {
            segmentItems.push(category);
          }
        });
      }
      return segmentItems;
    },
    filteredItems() {
      let items = this.items;
      let selectedSegmentId =
        this.selectedSegment > -1
          ? this.segments[this.selectedSegment]?.id
          : undefined;
      if (selectedSegmentId) {
        items = filter(items, (item) => {
          return item.category?.id === selectedSegmentId;
        });
      }
      return items;
    },
    sortedItems() {
      let items = this.filteredItems;
      // RO items first, then rest. Both parts sorted by distance.
      const pocoSort = function (i) {
        let reserveout = orderBy(
          filter(i, (o) => {
            return o.source === "reserve-out";
          }),
          ["distance"],
          ["asc"]
        );

        let rest = orderBy(
          filter(i, (o) => {
            return o.source !== "reserve-out";
          }),
          ["distance"],
          ["asc"]
        );

        return [...reserveout, ...rest];
      };
      let seoItems = items.filter((i) => i.seoRelevant);
      let otherItems = items.filter((i) => !i.seoRelevant);

      if (this.selectedSegment > -1 || !this.showSegmentedControl) {
        seoItems = pocoSort(seoItems);
        otherItems = pocoSort(otherItems);
      }

      return [...seoItems, ...otherItems];
    },
  },
  methods: {
    vanishItem(event) {
      event.$el.parentElement.style.transition = "transform 0.5s";
      event.$el.parentElement.style.transform =
        "translateX(-" + window.innerWidth * 2 + "px)";
      event.$el.parentElement.style.height =
        event.$el.parentElement.offsetHeight + "px";
      setTimeout(
        (element) => {
          element.classList.remove("col");
          element.classList.remove("col-12");
          element.style.transition = "height 0.5s";
          element.style.height = "0px";
        },
        500,
        event.$el.parentElement
      );
    },
  },
};
</script>
