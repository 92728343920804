<template>
  <v-card-actions class="pl-0 pr-0" v-if="category">
    <div v-if="category.pictogram" class="mr-2 gradient pa-2 rounded-circle">
      <v-img
        class="filter"
        width="20px"
        height="20px"
        :src="category.pictogram"
      />
    </div>
    <span
      v-if="category.title"
      :class="!category.pictogram ? 'ml-2' : ''"
      class="Text-Dark-Text-3---14-Left-Align mt-1"
      style="word-break: break-word"
    >
      {{ $t(`categories.${category.title}`) }}
    </span>
  </v-card-actions>
</template>
<script>
export default {
  name: "Category",
  data() {
    return {};
  },
  props: {
    category: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
